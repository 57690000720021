export default [
    {
        path: '/en/home',
        name: 'en-home',
        component: () => import('@/modules/en/Home'),
        meta: {
            requiresAuth: false,
            title: 'Fundación Rebeca Lan'
        },
    }
];
