export default [
    {
        path: '/aviso-de-privacidad',
        name: 'avisoprivacidad',
        component: () => import('@/modules/AvisoPrivacidad'),
        meta: {
            requiresAuth: false,
            title: 'Aviso de Privacidad | Fundación Rebeca Lan'
        },
    }
];
