export default [
    {
        path: '/en/beneficiarios',
        name: 'beneficiarios',
        component: () => import('@/modules/en/Beneficiarios'),
        meta: {
            requiresAuth: false,
            title: 'Beneficiarios | Fundación Rebeca Lan'
        },
    }
];
