import Vue from 'vue';
//import Vuex from 'vuex';
import config from '@/config';
import router from '@/config/routes';
//import store from '@/config/store';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

Vue.config.productionTip = false

import Meta from 'vue-meta';
Vue.use(Meta);

new Vue({
    router,
    render: h => h(config.defaultApp),
}).$mount(config.defaultAppId);