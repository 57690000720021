export default [
    {
        path: '/en/sumate',
        name: 'sumate',
        component: () => import('@/modules/en/Sumate'),
        meta: {
            requiresAuth: false,
            title: 'Súmate | Fundación Rebeca Lan'
        },
    }
];
