import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: 'my-app',
    storage: window.localStorage,
});

export default new Vuex.Store({
    plugins: [vuexPersist.plugin],
    state: {
        security : {},
        isLoggedIn: false,
        user: {},
        config: { theme: "default" },
    },
    mutations: {
        setDataSecurity(state, value){
            state.security = value;
        },
        setDataUser(state, value){
            state.user = value;
        },
        setLoginStatus(state, value) {
            state.isLoggedIn = value;
        },
        setDefaultData(state){
            state.security = {};
            state.isLoggedIn = false;
            state.user = {};
        }
    },
    getters: {
        config: (state) => (key) => {
            return state.config[key];
        }
    },
    actions: {
        getDataSecurity(context, value){
            context.commit('setDataSecurity', value);
        },
        getDataUser(context, value){
            context.commit('setDataUser', value)
        },
        loginUser(context, value) {
            context.commit('setLoginStatus', value);
        },
        logoutUser(context) {
            context.commit('setDefaultData')
        },

    },
});
