export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/modules/Home'),
        meta: {
            requiresAuth: false,
            title: 'Fundación Rebeca Lan'
        },
    }
];
