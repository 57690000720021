export default [
    {
        path: '/en/nuestro-trabajo',
        name: 'nuestrotrabajo',
        component: () => import('@/modules/en/NuestroTrabajo'),
        meta: {
            requiresAuth: false,
            title: 'Nuestro Trabajo | Fundación Rebeca Lan'
        },
    }
];
