export default [
    {
        path: '/nosotros',
        name: 'nosotros',
        component: () => import('@/modules/Nosotros'),
        meta: {
            requiresAuth: false,
            title: 'Nosotros | Fundación Rebeca Lan'
        },
    }
];
