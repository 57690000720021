export default [
    {
        path: '/en/nosotros',
        name: 'nosotros',
        component: () => import('@/modules/en/Nosotros'),
        meta: {
            requiresAuth: false,
            title: 'About Us | Fundación Rebeca Lan'
        },
    }
];
